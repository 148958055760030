import { Component, Input } from '@angular/core';

@Component({
  selector: 'neo-matris-card',
  templateUrl: './matris-card.component.html',
  styleUrls: ['./matris-card.component.scss']
})
export class MatrisCardComponent {
  @Input() header: any;
}
