import { Injectable } from '@angular/core';
import {
  RouteReuseStrategy,
  ActivatedRouteSnapshot,
  DetachedRouteHandle
} from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class RouteReuseService implements RouteReuseStrategy {
  routesToCache: string[] = ["/impulses/list", "/find-your-colleague", "orders/overview", "/part-package/list"];
  storedRouteHandles = new Map<string, DetachedRouteHandle>();

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const path = this.getFullRoute(route)
    return route.data['reuse'] === true;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    const path = this.getFullRoute(route)
    if (path) {
      this.storedRouteHandles.set(path, handle);
    }
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const path = this.getFullRoute(route);
    return path ? this.storedRouteHandles.has(path) : false;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    const path = this.getFullRoute(route);
    const handle = path ? this.storedRouteHandles.get(path) || null : null;
    if (handle) {
      const componentRef = handle as any;
      if (componentRef.componentRef.instance && typeof componentRef.componentRef.instance.getCache == 'function') {
        componentRef.componentRef.instance.getCache();
      }
    }
    return handle;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }

  private getFullRoute(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot.map(v => v.url.map(segment => segment.toString()).join('/'))
      .join('/');
  }

}
