import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AfterViewInit, Component, HostListener, Inject, Injectable, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject, filter, takeUntil } from 'rxjs';
import { TokenService } from './services/token.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { MatrisLoaderHandlerService } from './matrisneocommon/matris-loader-handler.service';
import { environment } from 'src/environments/environment';
import { ImpulsesService } from './services/impulses.service';
import { MatrisMenubar } from './matrisneocommon/matris-menubar/matris-menubar.component';
import { version } from 'src/version';
import { DOCUMENT } from '@angular/common';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  baseUrl = environment.baseUrl
  token: any;
  mobileMenuOpen = false;
  loginDisplay = false
  containerClasses = "page-container"
  username: string = ''
  companyName: string = ''
  showOrders: boolean = false;
  private readonly _destroying$ = new Subject<void>();
  globalId: any;
  globalPurchaserCode : any;
  userid: any;
  @ViewChild(MatrisMenubar) menubar: MatrisMenubar

  constructor(private userService: UserService, @Inject(DOCUMENT) private document: Document, private impulsesService: ImpulsesService, private httpClient: HttpClient, private tokenService: TokenService, private router: Router, private authService: MsalService, private msalBroadcastService: MsalBroadcastService
  ) {
    //console.log("MyApp version " + version.number);
  }

  @HostListener('window:resize', ['$event'])
  getRoute() {

    if (this.router.url == "/") {
      if (window.screen.availWidth > 600) {
        return this.setBackGroundImageClass("background-image", window.screen.availWidth)
      } else {
        return this.setBackGroundImageClass("background-image-mobile", window.screen.availWidth)
      }

    } else {
      return this.setBackGroundImageClass("", window.screen.availWidth)
    }
  }

  setBackGroundImageClass(className: string, width: number) {
    if (width > 600) {
      this.containerClasses = ""
      this.containerClasses = "page-container " + className
    } else {
      this.containerClasses = ""
      this.containerClasses = "page-container " + className
    }
    return this.containerClasses;
  }

  setFavicon() {
    const favicon: HTMLLinkElement | null = this.document.getElementById('appFavicon') as HTMLLinkElement;
    if (favicon) {
      favicon.href = environment.favicon;
    }
  }

  ngOnInit(): void {

    // this.tokenService.getToken().subscribe((data: any) => {
    //   localStorage.setItem('token', data["access_token"]);
    //   this.token = data["access_token"];
    // });

    this.setFavicon();

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.mobileMenuOpen = false;
      }
    });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });

  }

  ngAfterViewInit() {
    // this.toastService.setToast(this.toast);
    // Required for tab state service
  }

  getUserId(username: any) {
    return this.httpClient
      .get(this.baseUrl + `/get_userid_by_emailaddress?emailAddress=${username}`)
  }


  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    var currentAccount: any = this.authService.instance.getAllAccounts().length > 0 ? this.authService.instance.getAllAccounts()[0] : null;
    this.userService.setUsername(currentAccount.name);
    this.authService.acquireTokenSilent({
      account: currentAccount,
      scopes: environment.scopes,
      forceRefresh: false
    }).subscribe({

      next: (value: any) => {
        this.menubar.setMenubarItems();
        if (currentAccount != null) {
          this.username = currentAccount['username'];
          if (this.username != '') {
            this.getUserId(this.username).subscribe(data => {
              this.userid = data as any;
              this.globalId = this.userid[0].userid;
              this.globalPurchaserCode = this.userid[0].purchaserCode;
              localStorage.setItem('username', this.username)
              localStorage.setItem('globalId', this.globalId)
              localStorage.setItem('globalPurchaserCode', this.globalPurchaserCode)
            });
          }
          this.companyName = this.getCompanyName(this.username.split('@')[1])
        }
      },
      error: (err) => {
        this.authService.acquireTokenRedirect({ account : currentAccount, scopes : environment.scopes}).subscribe({
          next:(value:any) => {
            this.menubar.setMenubarItems();
        if (currentAccount != null) {
          this.username = currentAccount['username'];
          if (this.username != '') {
            this.getUserId(this.username).subscribe(data => {
              this.userid = data as any;
              this.globalId = this.userid[0].userid;
              this.globalPurchaserCode = this.userid[0].purchaserCode;
              localStorage.setItem('username', this.username)
              localStorage.setItem('globalId', this.globalId)
              localStorage.setItem('globalPurchaserCode', this.globalPurchaserCode)
            });
          }
          this.companyName = this.getCompanyName(this.username.split('@')[1])
        }
          }
        });
      },
    })


  }

  getCompanyName(value: string) {
    value = value.replace(".com", "")
    switch (value) {
      case "scania":
        return "Scania"
        break;
      case "man":
        return "MAN"
        break;
      case "vw":
        return "VW"
        break;
      default:
        return "Scania"
        break;
    }
  }

}

