import { Injectable } from '@angular/core';
import { ConfirmationService } from 'primeng/api';


@Injectable()
export class MatrisConfirmationService {

  constructor(private confirmationService: ConfirmationService) { }

  openDialog(key:string = '',message : string = '', header : string = '', icon : string = '') {
    this.confirmationService.confirm({
      key : key,
      message: message,
      header: header,
      icon: icon,
    });
  }

  closeDialog() {
    this.confirmationService.close();
  }

}
