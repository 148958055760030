import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'neo-matris-textarea',
  templateUrl: './matris-textarea.component.html',
  styleUrls: ['./matris-textarea.component.scss']
})
export class MatrisTextareaComponent {
  @Input() value :any
  @Input() id : string = ''
  @Input() width = '100%'
  @Input() isDisabled = false
  @Input() label : string = ''
  @Output() valueChange:EventEmitter<any> = new EventEmitter<any>();


  onInputChange(event:any){
    this.valueChange.emit({ name : this.id, value : event });
  }
}
