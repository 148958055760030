export function checkTimeRange(start: string, end: string): boolean {
    const currentTime = new Date();
    // Dates and times in "YYYY-MM-DD HH:mm:ss" format
    const startDateTime = parseUTCDate(start);
    const endDateTime = parseUTCDate(end);
    return currentTime >= startDateTime && currentTime <= endDateTime;
}

function parseUTCDate(dateTimeStr: string): Date {
    const [datePart, timePart] = dateTimeStr.split(' ');
    const [year, month, day] = datePart.split('-').map(Number);
    const [hours, minutes, seconds] = timePart.split(':').map(Number);
    const date = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));
    return date;
}