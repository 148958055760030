import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'neo-matris-splitbutton',
  templateUrl: './matris-splitbutton.component.html',
  styleUrls: ['./matris-splitbutton.component.scss']
})
export class MatrisSplitbuttonComponent {

  @Input() label: string;
  @Input() icon: string;
  @Input() items: any[];
  @Output() itemClicked = new EventEmitter<any>();
  @Input() splitButtonStyle = {};

  handleClick(event: any) {
    this.itemClicked.emit(event)
  }

}
