<div [ngClass]="{'mobile-menu-open': mobileMenuOpen }" class="sdds-sidebar side-menu">
    <div class="sdds-sidebar-mheader">
        <button (click)="closeMobileMenu()" class="sdds-sidebar-mheader__close">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M3.40338 2.34308C3.11048 2.05019 2.63561 2.05019 2.34272 2.34308C2.04982 2.63598 2.04982 3.11085 2.34272 3.40374L6.93897 8L2.34283 12.5961C2.04994 12.889 2.04994 13.3639 2.34283 13.6568C2.63572 13.9497 3.1106 13.9497 3.40349 13.6568L7.99963 9.06066L12.5958 13.6568C12.8887 13.9497 13.3635 13.9497 13.6564 13.6568C13.9493 13.3639 13.9493 12.889 13.6564 12.5961L9.06029 8L13.6565 3.40376C13.9494 3.11086 13.9494 2.63599 13.6565 2.3431C13.3636 2.0502 12.8888 2.0502 12.5959 2.3431L7.99963 6.93934L3.40338 2.34308Z"
                    fill="#171719" />
            </svg>
        </button>
        <br>
        <br>
        <br>
        <br>


        <ul class="sdds-sidebar-nav sdds-sidebar-nav--main ">
            <li class="sdds-sidebar-nav__item">
                <a class="sdds-sidebar-nav__item-link" routerLink="/home">
                    <span class="sdds-sidebar-nav__item-text">Home</span>
                </a>
            </li>

            <li class="sdds-sidebar-nav__item sdds-sidebar-nav__extended" [ngClass]="{'subnav-open': isSubmenuOpenOne}">
                <a class="sdds-sidebar-nav__item-link" (click)="toggleSubmenu()">
                    <span class="sdds-sidebar-nav__item-text">Impulses</span>
                    <svg class="sdds-sidebar-nav__chevron" width="12" height="7" viewBox="0 0 12 7" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L6 6L11 1" stroke="currentColor" stroke-width="1.25" stroke-linecap="round"
                            stroke-linejoin="round"></path>
                    </svg>
                </a>

                <!-- Sub menu -->
                <ul class="sdds-sidebar-nav-subnav">
                    <li class="sdds-sidebar-nav-subnav__item">
                        <span class="sdds-sidebar-nav__item-title">Sub-menu</span>
                    </li>
                    <li class="sdds-sidebar-nav-subnav__item">
                        <a class="sdds-sidebar-nav__item-link" routerLink="/impulses"><span
                                class="sdds-sidebar-nav__item-text">View Impulses</span></a>
                    </li>

                </ul>
                <!-- End of sub menu -->

                <!-- Start of second sub menu -->
            <li class="sdds-sidebar-nav__item sdds-sidebar-nav__extended" [ngClass]="{'subnav-open': isSubmenuOpenTwo}">
                <a class="sdds-sidebar-nav__item-link" (click)="toggleSubmenuTwo()">
                    <span class="sdds-sidebar-nav__item-text">Part Planning</span>
                    <svg class="sdds-sidebar-nav__chevron" width="12" height="7" viewBox="0 0 12 7" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L6 6L11 1" stroke="currentColor" stroke-width="1.25" stroke-linecap="round"
                            stroke-linejoin="round"></path>
                    </svg>
                </a>



            </li>
        </ul>
    </div>
</div>