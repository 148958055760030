import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'neo-matris-dialog',
  templateUrl: './matris-dialog.component.html',
  styleUrls: ['./matris-dialog.component.scss']
})
export class MatrisDialogComponent {
  @Input() header : string = ''
  @Input() visible : boolean = false;
  @Input() maximizable: boolean = false;
  @Output() onHide : EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() showOverlaySpinner: boolean = false;
  @Input() dialogStyle: any = { width: '50vw' };
  
  hide(event:any){
    this.onHide.emit(true);
  }
}
