import { Component } from '@angular/core';
import { MatrisToastService } from '../matris-toast.service';
// import { MessageService } from 'primeng/api';

@Component({
  selector: 'neo-matris-toast',
  templateUrl: './matris-toast.component.html',
  styleUrls: ['./matris-toast.component.scss']
})
export class MatrisToastComponent {
    
}
