import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Menu } from 'primeng/menu';

@Component({
  selector: 'neo-matris-menu',
  templateUrl: './matris-menu.component.html',
  styleUrls: ['./matris-menu.component.scss']
})
export class MatrisMenuComponent {
  @ViewChild('menu') menu!: Menu;

  toggle(event: any) {
    this.menu.toggle(event);
  }

  @Input() items: any[];
  @Input() appendTo: any;

}
