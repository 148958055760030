import { Component, Input } from '@angular/core';

@Component({
  selector: 'neo-matris-checkbox',
  templateUrl: './matris-checkbox.component.html',
  styleUrls: ['./matris-checkbox.component.scss']
})
export class MatrisCheckboxComponent {
  @Input() checkboxLabel: any;
  checked: boolean;
  

}
