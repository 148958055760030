<label class="label-font">
{{dropdownLabel}}
</label>
<br>
<p-dropdown  
 class="custom-dropdown"
 [autoDisplayFirst]="false"
 [options]="options"
 [pTooltip]="showTooltip ? 'Please fill this field' : ''"
 tooltipPosition="top"
 [disabled]="isDisabled"
 [filter]="filter" 
 [ngStyle]="{'--dropdown-border-color': (showError ? 'red' : '#989898') }"
 filterBy="name"
 [showClear]="showClear"
 [virtualScroll]="virtualScroll" 
 [virtualScrollItemSize]="virtualScrollItemSize"
 [optionLabel]="optionLabel"
 [optionValue]="optionValue"
 [ngModel]="selectedOption" 
 [placeholder]="placeholder"
 (onChange)="onChange($event)"
 autoWidth="false"
 [style]="customStyle"
 appendTo="body"
 [id]="id"
 #dropdownPanel
 >
 
 <ng-template let-item pTemplate="item">
    <div class='custom-dropdown-item'>{{item.name}}</div>
</ng-template>
</p-dropdown>
