
    <label [style]="customLableStyle">{{label}}</label>
    <br>
    <p-multiSelect 
    [options]="options" 
    [(ngModel)]="selectedOption" 
    optionLabel={{optionLabel}}
    optionValue={{optionValue}}
    (onChange) = "saveValues()"
    name="selectedOption" 
    [disabled]="disable"
    [ngClass]="invalidInput?'error-bg':'grey-bg'"
    [pTooltip]="invalidInput ? 'Please fill this field' : ''"
    [style]="customStyle">
    </p-multiSelect>


    <!-- //Class  -->
    <!-- <p-multiSelect 
    class="p-inputtext p-inputtext-sm"
    [options]="address" 
    [(ngModel)]="selectedAddress" 
    defaultLabel={{addressPlaceHolder}}
    optionLabel={{optionLabel}}
    (onChange) = "saveValues()"
    [style]="{'width': '93%','height':'55%'}">
    </p-multiSelect> -->
    