import { Component, Input } from '@angular/core';

@Component({
  selector: 'neo-matris-confirm-dialog',
  templateUrl: './matris-confirm-dialog.component.html',
  styleUrls: ['./matris-confirm-dialog.component.scss']
})
export class MatrisConfirmDialogComponent {
  @Input() key : string = ""
}
