import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class MatrisLoaderHandlerService {
  runningOperationCount = new Subject<number>();
  apiCounts : number = 0;
  constructor() { }

  addCount(){
    this.apiCounts =  this.apiCounts + 1
    this.runningOperationCount.next(this.apiCounts);
  }

  substractCount(){
    this.apiCounts =  this.apiCounts - 1
    this.runningOperationCount.next(this.apiCounts);
  }

}
