<div>
   <p-table #dt1 [ngStyle]="{'--disabled-table-border': (isTableBorderDisabled ? 'none' : '1px solid #dee2e6') }"
      styleClass="shared-table-style" [loading]="loading" [resizableColumns]="true" [scrollable]="true"
      styleClass="p-datatable-sm p-datatable-gridlines" [reorderableColumns]="reorderColumns" [columns]="columns"
      [value]="rows" [globalFilterFields]="getGlobalFilterFields(columns)" [selectionMode]="selectionMode"
      [(selection)]="selectedRow" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)">


      <ng-template *ngIf="showCaption" pTemplate="caption">
         <ng-content [select]="[globalFilter]">
         </ng-content>
      </ng-template>

      <ng-template pTemplate="header" let-columns>
         <tr [ngClass]="disableTable?'disableBg':''">
            <ng-container *ngIf="showColumns">
               <th *ngFor="let col of columns" pReorderableColumn [ngStyle]="{'display': col.display}">
                  {{ col.header }}
               </th>
            </ng-container>
         </tr>
      </ng-template>


      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">

         <!-- Row template for Skeleton loader -->
         <tr *ngIf="showTableSkeleton">
            <ng-container *ngFor="let col of columns">
               <td *ngIf="col.display != 'none'">
                  <p-skeleton></p-skeleton>
               </td>
            </ng-container>
         </tr>

         <tr *ngIf="!showTableSkeleton"
            [ngClass]="{'striped-row': rowData['highlightCell'] == true, 'disableBg' : disableTable}"
            [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
            <ng-container *ngIf="columnLevelEdit">
               <ng-container *ngFor="let col of columns">

                  <td *ngIf="col.columnType == 0 && !disableTable" [ngStyle]="{'display': col.display}"
                     [ngClass]="getTextAlignmentClass(col.dataType)">
                     {{ rowData[col.field] }}
                  </td>

                  <!-- Template for dotted Loader animation -->
                  <td *ngIf="col.columnType == 7" [ngStyle]="{'display': col.display}"
                     [ngClass]="getTextAlignmentClass(col.dataType)">
                     <div *ngIf="showLoadingColumn; else MAN" style="margin-left: 25%" class="loader"></div>
                     <ng-template #MAN>
                        {{ rowData[col.field] }}
                     </ng-template>
                  </td>

                  <!-- Template for Image Link / Part No -->
                  <td *ngIf="col.columnType == 8" [ngStyle]="{'display': col.display}">
                     <a [routerLink]="rowData['hyperlinkText']">{{ rowData[col.field] }}</a>
                     <a [href]="rowData['hyperlinkLogo']" target="_blank">
                        <img src="../assets/oas-logo.png" alt="OAS Logo"
                           style="width: 20px; height: 20px;  margin-left: 15px;">
                     </a>
                     <!-- part price history -->
                        <i  style="width: 20px; height: 20px;  margin-left: 15px;"
                         (click)="onHistoryLogoClick(rowIndex, col.field, rowData)" 
                         class="pi pi-history">
                        </i>
                  </td>

                  <!-- Template for Text and Image Link -->
                  <td *ngIf="col.columnType == 9" [ngStyle]="{'display': col.display}">
                     {{ rowData[col.field] }}
                     <a [href]="rowData['hyperlinkLogoeco']" target="_blank">
                        <img src="../assets/oas-logo.png" alt="OAS Logo"
                           style="width: 20px; height: 20px;  margin-left: 15px;">
                     </a>
                  </td>

                  <!-- Template for image  -->
                  <td *ngIf="col.columnType == 10">
                     <img [src]="rowData['IMAGE']" [alt]="rowData['IMAGEAlt']" [style]="rowData['IMAGEStyle']" />
                  </td>

                  <!-- Template for Dropdown with Text -->
                  <!-- Test ngModelChange -->
                  <td *ngIf="col.columnType == 11">
                     <div style="display: flex; align-items: center; width: 100%;">
                        <div class="editable-dropdownn" *ngIf="rowData.showDropdown">
                           <p-dropdown [(ngModel)]="rowData[col.field]" [autoWidth]="true" appendTo="body"
                              class="editable-dropdown" [options]="rowData[col.field+'Dropdown']" optionValue="code"
                              optionLabel="name" (ngModelChange)="onDropdownChange($event, col.field, rowData)"
                              [placeholder]="placeholder">
                           </p-dropdown>
                        </div>
                        <p style="margin-left: 12px">{{rowData['Text']}}</p>
                     </div>
                  </td>

                  <!-- Template for Dropdown -->
                  <td *ngIf="col.columnType == 12" [ngClass]="getTextAlignmentClass(col.dataType)">
                     <div class="editable-dropdownn" *ngIf="rowData.showSimpleDropdown">
                        <p-dropdown [style]="{'width':'80%'}" [(ngModel)]="rowData[col.field]" [autoWidth]="true"
                           appendTo="body" class="editable-dropdown" [options]="rowData[col.field+'Dropdown']"
                           optionValue="code" optionLabel="name"
                           (ngModelChange)="onDropdownChange($event, col.field, rowData)" [placeholder]="placeholder">
                        </p-dropdown>
                     </div>
                  </td>

                  <!-- Template for search field -->
                  <td *ngIf="col.columnType == 13" [ngClass]="getTextAlignmentClass(col.dataType)">
                     <div style="display: flex; align-items: center; justify-content: space-between;">
                        <span>{{ rowData[col.field] }}</span>
                        <i class="pi pi-search">
                        </i>
                     </div>
                  </td>

                  <!-- Template for selecting lead // star icon selection -->
                  <td *ngIf="col.columnType == 14" [ngClass]="getTextAlignmentClass(col.dataType)">
                     <div *ngFor="let item of rowData[col.field]; let itemIndex = index" class="star-container"
                        style="display: flex; align-items: center; justify-content: flex-start; gap: 4px;">

                        <span
                           [ngStyle]="{'font-weight': getStarClass(rowIndex, col.field, item) === 'pi pi-star-fill text-yellow-500' ? 'bold' : 'normal'}"
                           style="margin-right: 4px;">
                           {{ item }}
                        </span>

                        <div style="display: flex; align-items: center;">
                           <i *ngIf="!lockTable || getStarClass(rowIndex, col.field, item) === 'pi pi-star-fill text-yellow-500'"
                              [ngClass]="[getStarClass(rowIndex, col.field, item), lockTable ? 'disabled' : '']"
                              class="cursor-pointer" (click)="toggleStar(rowIndex, col.field, item, rowData)">
                           </i>

                           <i *ngIf="!lockTable" class="pi pi-trash cursor-pointer text-red-500"
                              style="margin-left: 4px;" (click)="deleteItem(rowData, col.field, itemIndex)">
                           </i>

                           <!-- Edit -->
                           <i *ngIf="!lockTable" class="pi pi-pencil cursor-pointer text-blue-500"
                              style="margin-left: 4px;" (click)="openEditDialog(rowData, item, rowIndex, col.field)">
                           </i>
                        </div>
                     </div>

                     <!-- Add Team Member (placed after the last item) -->
                     <div style="display: flex; justify-content: flex-start; margin-top: 8px;">
                        <i *ngIf="!lockTable" class="pi pi-plus cursor-pointer text-green-500"
                           (click)="onAddTeamMember(rowData, col.field, rowIndex)" title="Add Team Member">
                        </i>
                     </div>
                  </td>


                  <td disable="disableTable"
                     *ngIf="((col.columnType == 0 || col.columnType == 1 || col.columnType == 2 || col.columnType == 3 ||col.columnType == 4 ||col.columnType == 6 ) && disableTable )"
                     [ngClass]="getTextAlignmentClass(col.dataType)">
                     {{ rowData[col.field] }}
                  </td>

                  <td *ngIf="col.columnType == 5">
                     <a [routerLink]="rowData['hyperlinkText']">{{ rowData[col.field] }}</a>
                  </td>

                  <td [pEditableColumn]="rowData[col.field]" pEditableColumnField="col.field"
                     *ngIf="col.columnType == 1 && !disableTable" [ngClass]="getTextAlignmentClass(col.dataType)">
                     <div class="editable-dropdownn">
                        <p-cellEditor>
                           <ng-template let-rowIndex="rowIndex" pTemplate="input">
                              <p-dropdown [style]="{'width':'100%'}" [(ngModel)]="rowData[col.field]" [autoWidth]="true"
                                 appendTo="body" class="editable-dropdown" [options]="rowData[col.field+'Dropdown']"
                                 optionValue="code" optionLabel="name"
                                 (ngModelChange)="onDropdownChange($event, col.field, rowData)"
                                 [placeholder]="placeholder">
                              </p-dropdown>
                           </ng-template>
                           <ng-template pTemplate="output">
                              {{ rowData[col.field] }}
                           </ng-template>
                        </p-cellEditor>
                     </div>
                  </td>

                  <td [pEditableColumn]="rowData[col.field]" pEditableColumnField="col.field"
                     *ngIf="col.columnType == 2 && !disableTable" [ngClass]="getTextAlignmentClass(col.dataType)">
                     <div class="editable-datepickerr">
                        <p-cellEditor>
                           <ng-template let-rowIndex="rowIndex" pTemplate="input">
                  <td>
                     <input style="font-size: 0.8rem;" pInputText min="0" class="input-custom" type="number"
                        [(ngModel)]="rowData[col.field]" />
                  </td>
      </ng-template>
      <ng-template pTemplate="output">
         {{ rowData[col.field] }}
      </ng-template>
      </p-cellEditor>
</div>
</td>

<td [pEditableColumn]="rowData[col.field]" pEditableColumnField="col.field" *ngIf="col.columnType == 6 && !disableTable"
   [ngClass]="getTextAlignmentClass(col.dataType)">
   <div class="editable-datepickerr">
      <p-cellEditor>
         <ng-template let-rowIndex="rowIndex" pTemplate="input">
<td>
   <input style="font-size: 0.8rem;" appInputMaxvalue pInputText min="0" class="input-custom" type="number"
      [(ngModel)]="rowData[col.field]" />
</td>
</ng-template>
<ng-template pTemplate="output">
   {{ rowData[col.field] }}
</ng-template>
</p-cellEditor>
</div>
</td>

<td [pEditableColumn]="rowData[col.field]" pEditableColumnField="col.field" *ngIf="col.columnType == 3 && !disableTable"
   [ngClass]="getTextAlignmentClass(col.dataType)">
   <div class="editable-datepickerr">
      <p-cellEditor>
         <ng-template let-rowIndex="rowIndex" pTemplate="input">
            <p-calendar [style]="{'width':'100%'}" [showButtonBar]="true" dateFormat="yy-mm-dd"
               styleClass="editable-datepicker" [(ngModel)]="rowData[col.field]" appendTo="body"
               (ngModelChange)="onDateChange($event, col.field, rowData)">
            </p-calendar>
         </ng-template>
         <ng-template pTemplate="output">
            {{ rowData[col.field] | date : 'yyyy-MM-dd' }}
         </ng-template>
      </p-cellEditor>
   </div>
</td>

<td [pEditableColumn]="rowData[col.field]" pEditableColumnField="col.field" *ngIf="col.columnType == 4 && !disableTable"
   [ngClass]="getTextAlignmentClass(col.dataType)">
   <div class="editable-datepickerr">
      <p-cellEditor>
         <ng-template let-rowIndex="rowIndex" pTemplate="input">
<td>
   <input style="font-size: 0.8rem;" pInputText min="0" class="input-custom" type="text"
      [(ngModel)]="rowData[col.field]" />
</td>
</ng-template>
<ng-template pTemplate="output">
   {{ rowData[col.field] }}
</ng-template>
</p-cellEditor>
</div>
</td>
</ng-container>
</ng-container>
<ng-container *ngIf="rowLevelEdit">
   <ng-container *ngFor="let col of columns">

      <td *ngIf="rowData[col.field+'ControlType'] == undefined" [ngClass]="getTextAlignmentClass(col.dataType)">
         {{ rowData[col.field] }}
      </td>

      <td *ngIf="rowData[col.field+'ControlType'] == 0" [ngClass]="getTextAlignmentClass(col.dataType)">
         {{ rowData[col.field] }}
      </td>

      <td [pEditableColumn]="rowData[col.field]" pEditableColumnField="col.field"
         *ngIf="rowData[col.field+'ControlType'] == 1" [ngClass]="getTextAlignmentClass(col.dataType)">
         <div class="editable-dropdownn">
            <p-cellEditor>
               <ng-template let-rowIndex="rowIndex" pTemplate="input">
                  <p-dropdown [style]="{'width':'100%'}" [(ngModel)]="rowData[col.field]" [autoWidth]="true"
                     appendTo="body" class="editable-dropdown" [options]="rowData[col.field+'Dropdown']"
                     optionValue="code" optionLabel="name"
                     (ngModelChange)="onDropdownChange($event, col.field, rowData)" [placeholder]="placeholder">
                  </p-dropdown>
               </ng-template>
               <ng-template pTemplate="output">
                  {{ rowData[col.field] }}
               </ng-template>
            </p-cellEditor>
         </div>
      </td>

      <td [pEditableColumn]="rowData[col.field]" pEditableColumnField="col.field"
         *ngIf="rowData[col.field+'ControlType'] == 2" [ngClass]="getTextAlignmentClass(col.dataType)">
         <div class="editable-datepickerr">
            <p-cellEditor>
               <ng-template let-rowIndex="rowIndex" pTemplate="input">
      <td>
         <input pInputText min="0" class="input-custom" type="number" [(ngModel)]="rowData[col.field]" />
      </td>
      </ng-template>
      <ng-template pTemplate="output">
         {{ rowData[col.field] }}
      </ng-template>
      </p-cellEditor>
      </div>
      </td>

      <td [pEditableColumn]="rowData[col.field]" pEditableColumnField="col.field"
         *ngIf="rowData[col.field+'ControlType'] == 3" [ngClass]="getTextAlignmentClass(col.dataType)">
         <div class="editable-datepickerr">
            <p-cellEditor>
               <ng-template let-rowIndex="rowIndex" pTemplate="input">
                  <p-calendar [style]="{'width':'100%'}" [showButtonBar]="true" dateFormat="yy-mm-dd"
                     styleClass="editable-datepicker" [(ngModel)]="rowData[col.field]" appendTo="body"
                     (ngModelChange)="onDateChange($event, col.field, rowData)">
                  </p-calendar>
               </ng-template>
               <ng-template pTemplate="output">
                  {{ rowData[col.field] | date : 'yyyy-MM-dd' }}
               </ng-template>
            </p-cellEditor>
         </div>
      </td>
      <td [pEditableColumn]="rowData[col.field]" pEditableColumnField="col.field"
         *ngIf="rowData[col.field+'ControlType'] == 4" [ngClass]="getTextAlignmentClass(col.dataType)">
         <div class="editable-datepickerr">
            <p-cellEditor>
               <ng-template let-rowIndex="rowIndex" pTemplate="input">
      <td>
         <input pInputText min="0" class="input-custom" type="text" [(ngModel)]="rowData[col.field]" />
      </td>
      </ng-template>
      <ng-template pTemplate="output">
         {{ rowData[col.field] }}
      </ng-template>
      </p-cellEditor>
      </div>
      </td>
   </ng-container>
</ng-container>
</tr>
</ng-template>

<ng-template pTemplate="emptymessage">
   <tr>
      <td *ngIf="showEmptyMessage">No data found.</td>
   </tr>
</ng-template>

</p-table>

<neo-matris-paginator *ngIf="showPaginator" class="custom-paginator" [showCurrentPageReport]="true"
   currentPageReportTemplate="{first} - {last} of {totalRecords}" (pageChange)="onPageChange($event)" [first]="first"
   [rows]="20" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10, 20, 30]"></neo-matris-paginator>

</div>