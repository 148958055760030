import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { SearchComponent } from './search/search.component';
import { ImpulseListComponent } from './impulse/impulse-list/impulse-list.component';
import { HomeComponent } from './home/home.component';
import { DetailsModule } from './details/details.module';
//import { DetailsComponent } from './details/details.component';
import { DetailsComponent } from './details/details/details.component';
import { MsalGuard } from '@azure/msal-angular';
import { RequisitionDetailsComponent } from './details/requisition-details/requisition-details.component';
import { KnowYourColleagueComponent } from './know-your-colleague/know-your-colleague/know-your-colleague.component';
//import { RequisitionDetailsComponent } from './requisition-details/requisition-details.component';

const routes: Routes = [
  {
    'path': 'details/:partNumber/:requisitionNumber', loadChildren: () => import('./details/details.module')
      .then(m => m.DetailsModule), canActivate: [MsalGuard]
  },

  { path: '', component: HomeComponent, canActivate: [MsalGuard] },
  {
    'path': 'impulses', loadChildren: () => import('./impulse/impulse.module')
      .then(m => m.ImpulseModule), canActivate: [MsalGuard],
    data: { reuse: true }
  },
  { 'path': 'home', component: HomeComponent, canActivate: [MsalGuard] },
  //{ 'path': 'details/:rowItem', component: DetailsComponent, canActivate: [MsalGuard] },
  {
    'path': 'part-package', loadChildren: () => import('./part-package/part-package.module')
      .then(m => m.PartPackageModule), canActivate: [MsalGuard]
  },
  {
    'path': 'find-your-colleague', loadChildren: () => import('./know-your-colleague/know-your-colleague.module')
      .then(m => m.KnowYourColleagueModule), canActivate: [MsalGuard],
    data: { reuse: true }
  },
  {
    'path': 'orders', loadChildren: () => import('./prototype-orders/prototype-orders.module')
      .then(m => m.PrototypeOrdersModule), canActivate: [MsalGuard],
    data: { reuse: true }
  },
  {
    'path': 'procurement-strategy-hub', loadChildren: () => import('./procurement-strategy-hub/procurement-strategy-hub.module')
      .then(m => m.ProcurementStrategyHubModule), canActivate: [MsalGuard],
    data: { reuse: true }
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
