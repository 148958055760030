import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor() { }

  private _username: string;

  setUsername(username: string) {
    this._username = username;
  }

  getUsername(): string {
    return this._username;
  }

}
