import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Menu } from 'primeng/menu';

@Component({
  selector: 'neo-matris-button',
  templateUrl: './matris-button.component.html',
  styleUrls: ['./matris-button.component.scss']
})
export class MatrisButtonComponent {

  @Output() buttonClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() tooltipText: string = ''
  @Input() disabled: boolean = false
  @Input() buttonText: string = ''
  @Input() buttonStyle = {};
  @Input() icon: string = '';

  onClick(event: any) {
    this.buttonClick.emit(event);
  }
}
