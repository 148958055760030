import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'neo-matris-paginator',
  templateUrl: './matris-paginator.component.html',
  styleUrls: ['./matris-paginator.component.scss']
})
export class MatrisPaginatorComponent {

  @Input() showCurrentPageReport: boolean = true;
  @Input() currentPageReportTemplate: string = "{first} - {last} of {totalRecords}"
  @Input() first: number = 0;
  @Input() rows: number = 10;
  @Input() totalRecords: number = 0;
  @Input() rowsPerPageOption: number[] = [10, 20, 30]
  @Output() pageChange = new EventEmitter<any>();

  onPageChange(event: any) {
    this.pageChange.emit(event)
  }


}
