import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import qs from 'qs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})

export class TokenService {

  xApiKey = environment.xApiKey
  tokenUrl = environment.tokenUrl

  constructor(private http: HttpClient) { }

  getToken() {
    // var headers = {
    //   'x-api-key': this.xApiKey,
    //   'Content-Type': 'application/json'
    // }
    return this.http.post(this.tokenUrl, null)
  }


  getTokenSynchronous(){
    var request = new XMLHttpRequest(); 
    request.open('POST', this.tokenUrl, false);  // `false` 
    request.setRequestHeader('x-api-key',this.xApiKey);
    request.setRequestHeader('Content-Type','application/json')
    //makes the request synchronous
    request.send();

    if (request.status === 200) {
        return JSON.parse(request.response);
    } else {
        throw new Error('request failed');
    }
  }
}
