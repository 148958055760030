import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MatrisneocommonModule } from './matrisneocommon.module';

@Injectable()
export class MatrisToastService {

  constructor(private messageService : MessageService) { }

  showToast(severity : string = 'success',summary : string = '', details:string = ''){
    this.messageService.add({ severity: severity, summary: summary, detail: details, life : 3000 });
  }
  clear(){
    this.messageService.clear();
  }
}
