import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'app-matris-calendar',
  templateUrl: './matris-calendar.component.html',
  styleUrls: ['./matris-calendar.component.scss']
})
export class MatrisCalendarComponent{
  @Input() date: any;
  @Input() inputWidthCalendar: any;
  @Input() heightCalendar: any;
  @Input() label: string;
  @Input() disable: boolean;
  @Input() minDate: any;
  @Input() maxDate: any;
  @Input() deliveryDateToolTipMsg:string;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
 //@Output() blurChange: EventEmitter<any> = new EventEmitter<any>();
 @Input() invalidInput: boolean;
 onValueChange(event: any) {
   this.valueChange.emit({event: event });
 }
 // onBlurChange(event:any){
 //   this.blurChange.emit({event: event });
 // }
}
