import { Component, OnInit, ViewEncapsulation } from '@angular/core';
// import { ToastService } from '../services/toast.service';
// import { ToastComponent } from '../toast/toast.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  // element: ToastComponent;
  

  constructor() { }

  ngOnInit() {

  }

 



}



