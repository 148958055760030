import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'neo-matris-footer',
  templateUrl: './matris-footer.component.html',
  styleUrls: ['./matris-footer.component.scss']
})
export class MatrisFooterComponent {
  collection = [1, 2, 3, 4, 5, 6]
  constructor() {
    this.setFooterStyle();
  }

  setFooterStyle() {
    document.documentElement.style.setProperty('--footer-color', environment.footer);
  }

}


