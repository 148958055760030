import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatrisTableComponent } from './matris-table/matris-table.component';
import { FormsModule } from '@angular/forms';
// import { BrowserModule } from '@angular/platform-browser';
import { ToastModule } from 'primeng/toast';
import { MenubarModule } from 'primeng/menubar';
import { MatrisMenubar } from './matris-menubar/matris-menubar.component';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { MatrisFooterComponent } from './matris-footer/matris-footer.component';
import { TableModule } from 'primeng/table';
import { TableColumn } from './models/table-column.model';
import { CardModule } from 'primeng/card';
import { PaginatorModule } from 'primeng/paginator';
import { DropdownModule } from 'primeng/dropdown';
import { MatrisInputComponent } from './matris-input/matris-input.component';
import { MatrisDropdownComponent } from './matris-dropdown/matris-dropdown.component';
import { TabViewModule } from 'primeng/tabview';
import { CalendarModule } from 'primeng/calendar';
import { DividerModule } from 'primeng/divider';
import { MatrisCardComponent } from './matris-card/matris-card.component';
import { MatrisToastComponent } from './matris-toast/matris-toast.component';
import { MatrisCalendarComponent } from './matris-calendar/matris-calendar.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MatrisButtonComponent } from './matris-button/matris-button.component';
import { MatrisTextareaComponent } from './matris-textarea/matris-textarea.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MatrisToastService } from './matris-toast.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TooltipModule } from 'primeng/tooltip';
import { CheckboxModule } from 'primeng/checkbox';
import { MatrisCheckboxComponent } from './matris-checkbox/matris-checkbox.component';
import { MatrisLoaderComponent } from './matris-loader/matris-loader.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MatrisLoaderHandlerService } from './matris-loader-handler.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MatrisConfirmDialogComponent } from './matris-confirm-dialog/matris-confirm-dialog.component';
import { MatrisConfirmationService } from './matris-confirmation.service';
import { DialogModule } from 'primeng/dialog';
import { MatrisDialogComponent } from './matris-dialog/matris-dialog.component';
import { MatrisDialogService } from './matris-dialog.service';
import { MatrisPaginatorComponent } from './matris-paginator/matris-paginator.component';
import { MatrisSplitbuttonComponent } from './matris-splitbutton/matris-splitbutton.component';
import { MenuModule } from 'primeng/menu';
import { MatrisMenuComponent } from './matris-menu/matris-menu.component';
import { KeyFilterModule } from 'primeng/keyfilter';
import { MatrisMultiselectComponent } from './matris-multiselect/matris-multiselect.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputMaxvalueDirective } from './directives/input-maxvalue.directive';
import { BlockUIModule } from 'primeng/blockui';
import { SkeletonModule } from 'primeng/skeleton';
import { ChartModule } from 'primeng/chart';
import { SidebarModule } from 'primeng/sidebar';
import { ChipModule } from 'primeng/chip';
import { TabMenuModule } from 'primeng/tabmenu';

@NgModule({
  declarations: [
    MatrisTableComponent, MatrisMenubar, MatrisFooterComponent,
    MatrisDropdownComponent, MatrisInputComponent, MatrisCardComponent,
    MatrisToastComponent, MatrisCalendarComponent, MatrisButtonComponent,
    MatrisCheckboxComponent, MatrisTextareaComponent, MatrisToastComponent,
    MatrisLoaderComponent, MatrisConfirmDialogComponent, MatrisDialogComponent, MatrisPaginatorComponent, MatrisSplitbuttonComponent, MatrisMenuComponent, MatrisMultiselectComponent, InputMaxvalueDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    MenubarModule,
    InputTextModule,
    ButtonModule,
    TableModule,
    CardModule,
    PaginatorModule,
    DropdownModule,
    TabViewModule,
    CalendarModule,
    CardModule,
    ToastModule,
    ChartModule,
    DividerModule,
    OverlayPanelModule,
    InputTextareaModule,
    MessageModule,
    MessagesModule,
    TooltipModule,
    CheckboxModule,
    ProgressSpinnerModule,
    ConfirmDialogModule,
    DialogModule,
    SplitButtonModule,
    MenuModule,
    KeyFilterModule,
    MultiSelectModule,
    BlockUIModule,
    SkeletonModule,
    SidebarModule,
    ChipModule,
    TabMenuModule
  ],
  exports: [
    MatrisTableComponent,
    MatrisCalendarComponent,
    MatrisMenubar,
    MatrisFooterComponent,
    MatrisInputComponent,
    MatrisDropdownComponent,
    MatrisCardComponent,
    MatrisToastComponent,
    MatrisButtonComponent,
    MatrisTextareaComponent,
    MatrisToastComponent,
    TabViewModule,
    CardModule,
    ButtonModule,
    ToastModule,
    MessageModule,
    MessagesModule,
    MatrisCheckboxComponent,
    CheckboxModule,
    MatrisLoaderComponent,
    DividerModule,
    MatrisConfirmDialogComponent,
    CalendarModule,
    MatrisDialogComponent,
    MatrisPaginatorComponent,
    MatrisSplitbuttonComponent,
    MenuModule,
    MatrisMenuComponent,
    MatrisMultiselectComponent,
    BlockUIModule,
    ProgressSpinnerModule,
    SkeletonModule,
    SidebarModule,
    ChipModule,
    TabMenuModule
  ],
  providers: [MatrisToastService, MessageService, MatrisLoaderHandlerService, ConfirmationService,
    MatrisConfirmationService, MatrisDialogService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class MatrisneocommonModule { }
