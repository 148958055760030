export const environment = {
    clientId: '985d3eb5-fb9a-4cde-870b-84c111a6ddb1',
    redirectUrl: 'https://matrisneo.purapi.devtest.aws.scania.com',
    authority: 'https://login.microsoftonline.com/3bc062e4-ac9d-4c17-b4dd-3aad637ff1ac',
    baseUrl: 'https://2svxxz0yy9.execute-api.eu-west-1.amazonaws.com/dev',
    xApiKey: 'BY7tEQIKt44K5aWYTnKYr8YnGKZmfDGJ5y20zp8z',
    tokenUrl: 'https://2svxxz0yy9.execute-api.eu-west-1.amazonaws.com/dev/token',
    version: '1.0.1',
    scopes: ['api://73e92719-a258-4ccf-a6d5-86282cd6f055/user_access'],
    production: false,
    favicon: './assets/DEV_resized-removebg-preview.png',
    menubar: '#585175',
    hover: '#302d3c', // Menubar Hover
    footer: '#585175'
}