import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-matris-multiselect',
  templateUrl: './matris-multiselect.component.html',
  styleUrls: ['./matris-multiselect.component.scss']
})
export class MatrisMultiselectComponent {
  // addressArr = [
  //   { name: "New York", code: "NY" },
  //   { name: "Rome", code: "RM" },
  //   { name: "London", code: "LDN" },
  //   { name: "Istanbul", code: "IST" },
  //   { name: "Paris", code: "PRS" }
  // ];
  // selectedAddress:string[] = [];


  @Input() options: any;
  @Input() selectedOption: string[]; 
  @Input() placeHolder: string;
  @Input() optionLabel: string;
  @Input() optionValue: string
  @Input() label: any;
  @Input() invalidInput: boolean; 
  @Input() disable: boolean;
  @Input() customStyle: any = {};
  @Input() customLableStyle: any = {};  
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  saveValues(){
    this.valueChange.emit(this.selectedOption);
  }
}
