import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent {
  isSubmenuOpenOne = false;
  isSubmenuOpenTwo = false;
  @Input() mobileMenuOpen = false;
  @Output() eventFromSidebar = new EventEmitter<any>();

  closeMobileMenu() {
    this.eventFromSidebar.emit({
      type: 'showMobileMenu',
      value: false,
    });
  }

  toggleSubmenu() {
    this.isSubmenuOpenOne = !this.isSubmenuOpenOne;
    this.isSubmenuOpenTwo = false
  }

  toggleSubmenuTwo() {
    this.isSubmenuOpenTwo = !this.isSubmenuOpenTwo;
    this.isSubmenuOpenOne = false
  }

  

}
