import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TabStateService {

  constructor() { }
  selectedTabIndexKey = 'selectedTabIndex';
  isTQDCSActive = false;
  isMATGActive = false;

  getSelectedTabIndex(): number {
    const storedIndex = localStorage.getItem(this.selectedTabIndexKey);
    return storedIndex !== null ? +storedIndex : 0;
  }

  setSelectedTabIndex(index: number): void {
    localStorage.setItem(this.selectedTabIndexKey, index.toString());
  }
}
