import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appInputMaxvalue]'
})
export class InputMaxvalueDirective {

  private readonly maxValue = 100;
  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    const input = this.el.nativeElement as HTMLInputElement;
    const value = parseFloat(input.value + event.key);
    if (value > this.maxValue) {
      event.preventDefault();
    }
  }
}





