<!-- Menubar Skeleton -->
<p-menubar *ngIf="showMenubarSkeleton">
  <ng-template pTemplate="start">
    <div class="flex align-items-center">
      <span (click)="redirectToHomePage()" class="p-1 header-logo"
        style="font-weight:normal; color:white; font-family: 'Scania Sans Headline', Arial, Helvetica, sans-serif;">
        MATRIS NEO <span style="font-size: 0.6em;"> {{header}} {{version}}</span>
      </span> &nbsp; &nbsp;
      <p-skeleton class="custom-skeleton" width="9rem" height="1.6rem"></p-skeleton>
      <p-skeleton class="custom-skeleton" width="9rem" height="1.6rem"></p-skeleton>
      <p-skeleton class="custom-skeleton" width="9rem" height="1.6rem"></p-skeleton>
      <p-skeleton class="custom-skeleton" width="9rem" height="1.6rem"></p-skeleton>
      <p-skeleton class="custom-skeleton" width="9rem" height="1.6rem"></p-skeleton>
    </div>
  </ng-template>
  <ng-template pTemplate="end">
    <p-button (click)="op.toggle($event)" styleClass="menu-button" class="pr-2"><i class="pi pi-user"
        style="font-size: 1.2rem;"></i></p-button>
    <p-overlayPanel #op>
      <div>
        <span class="pi pi-user"></span> &nbsp; <span style="font-family: 'Scania Sans Condensed';"
          class="userinfo-font">{{username}}</span>
      </div>
      <br>
      <div>
        <span class="pi pi-building"></span> &nbsp; <span style="font-family: 'Scania Sans Condensed';"
          class="userinfo-font">{{companyName}}</span>
      </div>
    </p-overlayPanel>
    <p-button (onClick)="goToHome()" styleClass="menu-button"><img
        src="https://cdn.digitaldesign.scania.com/logotype/1.0.0/scania_symbol/scania-symbol.svg"
        style="width:1.5rem;height:1.5rem" />
    </p-button>
  </ng-template>
</p-menubar>


<!-- Menubar -->
<p-menubar *ngIf="!showMenubarSkeleton" [model]="items" styleClass="testing-class">
  <ng-template pTemplate="start">
    <span (click)="redirectToHomePage()" class="p-1 header-logo"
      style="font-weight:normal; color:white; font-family: 'Scania Sans Headline', Arial, Helvetica, sans-serif;">MATRIS
      NEO <span style="font-size: 0.6em;">{{header}} {{version}}</span></span>
  </ng-template> &nbsp; &nbsp;
  <ng-template pTemplate="end">
    <p-button (click)="op.toggle($event)" styleClass="menu-button" class="pr-2"><i class="pi pi-user"
        style="font-size: 1.2rem;"></i></p-button>
    <p-overlayPanel #op>
      <div>
        <span class="pi pi-user"></span> &nbsp; <span style="font-family: 'Scania Sans Condensed';"
          class="userinfo-font">{{username}}</span>
      </div>
      <br>
      <div>
        <span class="pi pi-building"></span> &nbsp; <span style="font-family: 'Scania Sans Condensed';"
          class="userinfo-font">{{companyName}}</span>
      </div>
    </p-overlayPanel>
    <p-button (onClick)="goToHome()" styleClass="menu-button"><img
        src="https://cdn.digitaldesign.scania.com/logotype/1.0.0/scania_symbol/scania-symbol.svg"
        style="width:1.5rem;height:1.5rem" />
    </p-button>
  </ng-template>
</p-menubar>

<!-- Show banner based on AWS App Config Configuration -->
<p-messages *ngIf="isBannerMessageVisible" [(value)]="messages" />