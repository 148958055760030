import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
// import { defineCustomElements as Test, addTheme } from '@scania/components';
// import { defineCustomElements } from '@scania/tegel/loader';
// import { theme as scania } from '@scania/theme-light';

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

//   // Run function to initiate component
//   Test();
//   defineCustomElements(window);

//  // add theme
//  addTheme(scania)