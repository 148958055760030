<!-- <p-dialog [modal]="true" [header]="header" [(visible)]="visible" 
[style]="{width: '50vw'}" 
(onHide)="hide($event)" [maximizable]="maximizable">
<ng-content [select]="[dialogContent]"></ng-content> -->
<!-- <p-progressSpinner styleClass="w-4rem h-4rem" 
strokeWidth="3" class="progress-spinner" 
*ngIf="showOverlaySpinner">
</p-progressSpinner> -->
<!-- </p-dialog> -->

<p-dialog [modal]="true" [header]="header" 
          [(visible)]="visible" 
          [style]="dialogStyle" 
          (onHide)="hide($event)" 
          [maximizable]="maximizable">
<ng-content [select]="[dialogContent]"></ng-content>
</p-dialog>
