<label style="font-weight: normal;font-size: smaller;font-family: 'Scania Sans Condensed';">{{label}}</label>
<br>
<!-- <p-calendar 
[inputStyleClass]="invalidInput ? 'error-bg' : ''"
[showOnFocus]="false" 
[readonlyInput]="false"
[pTooltip]="invalidInput ? 'Please fill this field' : ''"
[(ngModel)]="date" 
(onBlur)="onValueChange($event)"
[showIcon]="true">
</p-calendar> -->
<input type="date" 
class="p-inputtext p-inputtext-sm"
[disabled]="disable == true"
min="{{minDate}}"
max="9999-12-31"
(change)="onValueChange($event)" 
(blur)="onValueChange($event)"
[ngClass]="invalidInput?'error-bg':'grey-bg'"
[pTooltip]="deliveryDateToolTipMsg"
[tooltipPosition]="'top'"
[(ngModel)]="date"
/>
<!-- [pTooltip]="invalidInput ? 'Please fill this field' : ''" -->
<!-- max="{{maxDate}}" -->
