import { Component } from '@angular/core';
import { MatrisLoaderHandlerService } from '../matris-loader-handler.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'neo-matris-loader',
  templateUrl: './matris-loader.component.html',
  styleUrls: ['./matris-loader.component.scss']
})
export class MatrisLoaderComponent {

  count: Subject<number> = this.matrisLoaderHandlerService.runningOperationCount; 
  constructor(private matrisLoaderHandlerService : MatrisLoaderHandlerService) {
    
  }




}
