import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable, ReplaySubject, catchError, finalize, from, lastValueFrom, throwError } from "rxjs";
import { environment } from 'src/environments/environment';
import { TokenService } from "../services/token.service";
import { MatrisLoaderHandlerService } from "../matrisneocommon/matris-loader-handler.service";
import { DetailsService } from "../services/details.service";
import { MsalService } from "@azure/msal-angular";
import { AccountInfo, InteractionRequiredAuthError } from "@azure/msal-browser";
import { isNullOrUndef } from "chart.js/dist/helpers/helpers.core";
import { isNullOrUndefined } from "../utils/StringUtil";
import { CookieService } from "ngx-cookie-service";
import { MatrisDialogComponent } from "../matrisneocommon/matris-dialog/matris-dialog.component";

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
    /**
     *
     */
    timeout: any = null
    constructor(private tokenService: TokenService, private authService: MsalService, private matrisDialogComponent: MatrisDialogComponent) {

    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.handle(req, next))
    }

    async getAccessTokenKey() {
        let storageKey = 'msal.token.keys.' + environment.clientId
        let keysData = localStorage.getItem(storageKey)
        let parsedKeyData = keysData != null ? JSON.parse(keysData) : { 'accessToken': [] }
        let accessKeyData = isNullOrUndefined(parsedKeyData) ? [] : parsedKeyData['accessToken']
        let accessTokenKey = '';
        accessKeyData.forEach((j: string) => {
            if (j.includes(environment.scopes[0])) {
                accessTokenKey = j;
            }
        });

        if (accessTokenKey == null || accessTokenKey == undefined || accessTokenKey == '') {
            let account = this.getAccount()
            if(account == null){
                await this.authService.logoutRedirect();    
            } 
            
            await this.authService.logoutRedirect({ account: account })
        }

        return accessTokenKey
    }

    getAccount() {
        let accounts = this.authService.instance.getAllAccounts();
        if (accounts.length > 0) {
            return accounts[0]
        } else {
            return null
        }
    }

    async getTokenFromLocalStorage() {
        let accessTokenKey = await this.getAccessTokenKey()
        let cacheToken = localStorage.getItem(accessTokenKey)
        if (cacheToken == null || cacheToken == undefined || cacheToken == "") {
            let account : any = this.getAccount();
            await this.authService.instance.acquireTokenSilent({
                scopes: environment.scopes,
                account: account,
                forceRefresh: false
            }).catch(async (error) => {
                await this.authService.instance.acquireTokenRedirect({scopes: environment.scopes,account: account})
            });

            cacheToken = localStorage.getItem(accessTokenKey)
            return cacheToken;
        } else {
            return JSON.parse(cacheToken);
        }
    }

    async handle(req: HttpRequest<any>, next: HttpHandler) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(async () => {
            let account = this.getAccount() //this.authService.instance.getAllAccounts()[0]
            if(account == null){
                await this.authService.logoutRedirect();    
            }

            await this.authService.instance.clearCache()
            localStorage.clear()
            await this.authService.logoutRedirect({ account: account })
        }, 18000 * 1000)
        let accessTokenKey = await this.getAccessTokenKey()
        let cacheToken = localStorage.getItem(accessTokenKey)
        let token: any = null
        if (cacheToken == null || cacheToken == undefined || cacheToken == "") {
            let account: any = this.getAccount();
            if(account == null){
                await this.authService.logoutRedirect();    
            }
            await this.authService.instance.acquireTokenSilent({
                scopes: environment.scopes,
                account: account,
                forceRefresh: false
            }).catch(async (error) => {
                await this.authService.instance.acquireTokenRedirect({scopes: environment.scopes,account: account})
            });
            token = await this.getTokenFromLocalStorage()
        } else {
            let parsedToken: any = JSON.parse(cacheToken)
            var tokendate = new Date(parsedToken['expiresOn'] * 1000)
            var currDate = new Date()

            if (Math.floor((currDate.getTime() - tokendate.getTime()) / 1000) > 18000) {
                let account : any = this.getAccount()
                if(account == null){
                    await this.authService.logoutRedirect();    
                }
                let logoutClaim = account?.idTokenClaims?.login_hint;
                await this.authService.instance.clearCache()
                await this.authService.logoutRedirect({ account: account, logoutHint: logoutClaim })
            }

            if (tokendate < currDate) {
                let account : any = this.getAccount()
                if(account == null){
                    await this.authService.logoutRedirect();    
                }
                await this.authService.instance.acquireTokenSilent({
                    account: account,
                    scopes: environment.scopes,
                    forceRefresh: false
                }).catch(async (error) => {
                    await this.authService.instance.acquireTokenRedirect({scopes: environment.scopes,account: account})
                });

                token = await this.getTokenFromLocalStorage()
            } else {
                token = parsedToken
            }
        }

        if(token == null || token == undefined || token['secret']  == null || token['secret']  == undefined){
            token = await this.getTokenFromLocalStorage()
        }

        const authReq = req.clone({
            setHeaders: { 'x-api-key': environment.xApiKey, 'Authorization': token['secret']  }
        });
        return lastValueFrom(next.handle(authReq).pipe(catchError((err) => {
            //var token = this.tokenService.getTokenSynchronous();
            if ([401, 403].includes(err.status)) {
                
                const tokenReq = req.clone({
                    setHeaders: { 'x-api-key': environment.xApiKey, 'Authorization': token['secret'] }
                });

                return next.handle(tokenReq);
            }

            return throwError(err);

        })));
    }
}