export const MENU_ITEMS = [
  {
    label: 'Impulses', routerLink: 'impulses', command: () => {
      MENU_ITEMS[1].routerLink = "";
      if (MENU_ITEMS.find(e => e.label === 'Procurement Strategy Hub')) {
        MENU_ITEMS[4].routerLink = "";
      }
    }
  },
  {
    label: 'Package Planning', routerLink: "", command: () => {
      if (MENU_ITEMS.find(e => e.label === 'Procurement Strategy Hub')) {
        MENU_ITEMS[4].routerLink = "";
      }
    },
    items: [
      {
        label: 'View Packages', routerLink: "part-package/list", command: () => {
          MENU_ITEMS[1].routerLink = "part-package/list";
        }
      },
      {
        label: 'Create Packages', routerLink: "part-package/create-package", command: () => {
          MENU_ITEMS[1].routerLink = "part-package/create-package";
        }
      },
    ]
  },
  {
    label: 'Find Your Colleague', routerLink: 'find-your-colleague', command: () => {
      MENU_ITEMS[1].routerLink = "";
      if (MENU_ITEMS.find(e => e.label === 'Procurement Strategy Hub')) {
        MENU_ITEMS[4].routerLink = "";
      }
    }
  }
];
